<template>
  <div id="admissionExams">
    <!-- -----------------------------------second data table --------------------------->
    <v-data-table
      v-can="'list-admission-exam'"
      :options.sync="options"
      :server-items-length="this.totalApplicants"
      :headers="headers"
      :items="applicants"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1 level1"
      hide-default-footer
    >
      <template v-slot:top>
        <v-row class="headerTable-div">
          <v-col md="4" sm="12">
            <h3 class="custom-header main-color">
              {{ $t("Addmission Exam") }}
            </h3>
          </v-col>
          <v-col md="4" sm="12">
            <label class="search" for="inpt_search">
              <input
                id="inpt_search"
                @focus="inputFocus"
                @blur="inputBlur"
                type="text"
                v-model="search"
              />
            </label>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.notes="{ item }">
        <v-tooltip bottom max-width="300px">
          <template v-slot:activator="{ on }">
            <p dark v-on="on" v-if="item.notes">
              {{ item.notes.slice(0, 20) }}
            </p>
          </template>
          <span>{{ item.notes }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.student_full_name="{ item }">
        <v-tooltip bottom max-width="300px">
          <template v-slot:activator="{ on }">
            <p dark v-on="on" v-if="item.student_full_name">
              {{ item.student_full_name.slice(0, 20) }}
            </p>
          </template>
          <span>{{ item.student_full_name }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.action="{ item }">
        <a
          class="mr-1"
          small
          style=""
          @click="showDialog(item)"
          v-if="changeResultBtnVisible"
          :title="$t('Admission Exam Result')"
        >
          <i class="fas fa-chalkboard-teacher" style="color: gray"></i>
        </a>
        <v-icon @click="showNotification(item)" :title="$t('Applicant Email')"
          >mail</v-icon
        >
      </template>
    </v-data-table>
    <div class="text-center pt-2" v-can="'list-admission-exam'">
      <v-pagination
        v-if="length > 0 && totalApplicants > 0"
        v-model="page"
        :length="length"
        :circle="circle"
        :page="page"
        :total-visible="totalVisible"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
      ></v-pagination>
    </div>
    <changeExamResult
      v-if="changeResultBtnVisible"
      v-bind:data="applicant"
      v-bind:dialog="dialog"
      v-on:childToParent="onChildClick"
    ></changeExamResult>
    <SendNotificationApplicant
      v-bind:data="applicant"
      v-bind:dialogNotification="dialogNotification"
      v-on:childToParent="onChildClick"
    ></SendNotificationApplicant>
  </div>
</template>
<script>
import axios from "axios";
import SendNotificationApplicant from "../../components/modals/SendNotificationApplicant.vue";
import changeExamResult from "../../components/changeExamResultComponent.vue";
import ACL from "../../acl";
export default {
  components: { changeExamResult, SendNotificationApplicant },
  data() {
    return {
      changeResultBtnVisible: ACL.checkPermission(
        "change-admission-exam-result"
      ),
      //pagination options
      page: 0,
      circle: true,
      length: 0,
      totalVisible: 3,
      // end pagination
      dialogConfirm: false,
      dialogConfirmrefund: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      applicantLoaded: false,
      loaded: true,
      dialog: false,
      dialogNotification: false,
      applicant: [],
      headers: [
        {
          text: this.$i18n.t("Code"),
          align: "left",
          value: "application_number",
        },
        { text: this.$i18n.t("Name"), value: "student_full_name" },
        { text: this.$i18n.t("Grade"), value: "student_grade_level" },
        { text: this.$i18n.t("Fee Plan"), value: "price_plan" },

        {
          text: this.$i18n.t("Payment Status"),
          value: "exam_payment_status",
          sortable: false,
        },
        {
          text: this.$i18n.t("Notes"),
          value: "notes",
          sortable: false,
          width: "15%",
        },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          width: "6%",
        },
      ],
      applicants: [],
      loading: true,
      options: {
        itemsPerPage: 15,
      },
      totalApplicants: 0,
      search: "",
      // action menus
      actionMenus: [{ title: "ExamResult", icon: "edit" }],
    };
  },
  computed: {},
  watch: {
    $route: {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.getDataFromApi(this.$route.query.page);
        }
        // let pageNumber = this.$router.currentRoute.params.pageNumber;
        // this.getDataFromApi(pageNumber);
      },
    },
    options: {
      handler() {
        if (
          this.options.sortBy.length > 0 &&
          this.options.sortDesc.length > 0
        ) {
          this.getDataFromApi(this.page, this.search);
        }
      },
      deep: true,
    },
    search: {
      handler() {
        this.page = 1;
        this.getDataFromApi(1, this.search);
      },
      deep: true,
    },
    page: function () {
      this.$router.push(
        {
          path: "/admissionExam?page=" + this.page,
        },
        () => {}
      );
    },
  },
  methods: {
    confirmPay() {
      this.acceptPayment(this.applicant.id, true, this.dialogConfirm);
    },
    confirmRefund() {
      this.acceptPayment(this.applicant.id, false, this.dialogConfirmrefund);
    },
    close() {
      this.paymentStatus.notes = "";
      // console.log(this.paymentStatus.notes);
      this.dialogfinanial = false;
    },
    onChildClick(applicantLoaded, dialog) {
      this.applicantLoaded = applicantLoaded;
      this.dialog = dialog;
      this.dialogNotification = dialog;

      this.applicants = [];
      this.$nextTick(function () {
        // DOM is now updated
        // `this` is bound to the current instance
        // this.$forceUpdate();
        this.examResult();
      });
    },
    showDialog(item) {
      this.dialog = true;
      this.applicant = item;
      this.applicantLoaded = true;
      // document.getElementById("preloader-custom").classList.add("hidden");
    },
    showNotification(item) {
      this.dialogNotification = true;
      this.applicant = item;
      this.applicantLoaded = true;
      console.log(this.dialogNotification);
      // document.getElementById("preloader-custom").classList.add("hidden");
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
      }
    },
    getDataFromApi(page, search) {
      let sort_type = "";
      let column = "";
      if (this.options.sortBy.length > 0) {
        column = this.options.sortBy[0];
        sort_type = this.options.sortDesc[0] ? "DESC" : "ASC";
      }
      if (search == "") {
        axios
          .get(
            this.getApiUrl +
              "/applicant/applicants?pending_exam_result=yes&sort_type=" +
              sort_type +
              "&column=" +
              column +
              "&" +
              this.type +
              "=yes&page=" +
              page,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.applicants = response.data.data.data;
            this.totalApplicants = response.data.data.total;
            this.page = response.data.data.current_page;
            this.length = response.data.data.last_page;
            if (this.length > 5) this.totalVisible = 5;
          });
        // .catch(err => {});
      } else {
        axios
          .get(
            this.getApiUrl +
              "/applicant/applicants?pending_exam_result=yes&sort_type=" +
              sort_type +
              "&column=" +
              column +
              "&" +
              this.type +
              "=yes&page=" +
              page +
              "&search=" +
              this.search,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.applicants = response.data.data.data;
            this.totalApplicants = response.data.data.total;
            this.page = response.data.data.current_page;
            this.length = response.data.data.last_page;
            if (this.length > 5) this.totalVisible = 5;
          });
        // .catch(err => {});
      }
    },
    examResult() {
      axios
        .get(
          this.getApiUrl +
            "/applicant/applicants?pending_exam_result=yes&page=" +
            this.page,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.applicants = response.data.data.data;
            this.totalApplicants = response.data.data.total;
            this.page = response.data.data.current_page;
            this.length = response.data.data.last_page;
            if (this.length > 5) this.totalVisible = 5;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  mounted() {
    this.page = this.$route.query.page;
    this.getDataFromApi(this.page);

    if (!this.changeResultBtnVisible) {
      this.headers = this.headers.filter(function (row) {
        return row.value != "action";
      });
    }
    this.examResult();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";

#admissionExams {
  margin-top: 1.7rem;
}

// should apply on all views
#wrapper {
  transition: all 0.4s ease 0s;
}
#wrapper.active {
  // padding-left: 200px;
}

/* start new interface */

// start search input
.search {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  margin: 0px 8px 7px 0px;
  padding: 7px 9px 0px 9px;
  border: 2px solid #bbbbbb;
  border-radius: 25px;
  transition: all 200ms ease;
  cursor: text;
  padding: 3px 5px;

  &:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 15px;
    right: -5px;
    top: 21px;
    background: #bbbbbb;
    border-radius: 3px;
    transform: rotate(-45deg);
    transition: all 200ms ease;
  }

  &.active,
  &:hover {
    width: 200px;
    margin-right: 0px;

    &:after {
      height: 0px;
    }
  }

  input {
    width: 100%;
    border: none;
    box-sizing: border-box;
    font-family: Helvetica;
    font-size: 15px;
    color: inherit;
    background: transparent;
    outline-width: 0px;
  }
}

// end search input

.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}
/* end new interface */
</style>
